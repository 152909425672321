import { Injectable } from '@angular/core';
import { RouterStateSnapshot } from '@angular/router';
import { Globals } from '.././globals';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
declare var $: any;

@Injectable()
export class AuthGuard  { 

  constructor(private router: Router,private authService: AuthService,public globals: Globals, private CookieService: CookieService) { }

  canActivate(route, state: RouterStateSnapshot) { 
    // debugger
    window.scrollTo({top: 0, behavior: 'smooth'});
    
    if((state.url.split('/')[1]).split('?')[0] == 'landing-dashboard' || ((state.url.split('/')[1]).split('?')[0]) == 'authentication'){
      // this.globals.isLoading = true;
    }

    if (this.authService.isLoggedIn() == true) { 
      if(this.globals.authData!=null && this.globals.authData.id==this.CookieService.get('user_token')){
        this.globals.IsLoggedIn = true;
        if (((state.url.split('/')[1]).split('?')[0]) == 'authentication' || state.url == '/admin/login') {
          this.router.navigate(['/landing-dashboard']);
          return false;
        } else if(this.globals.institute_details==null && (state.url.split('/')[1]).split('?')[0] != 'landing-dashboard') {
          this.router.navigate(['/landing-dashboard']);
          return false;
        } else {
          return true;
        }    
      } else {
        this.authService.getAuthUser()
        .then((data) => { 
          this.router.navigate([state.url]);
          return false;
        },
        (error) => {
          this.globals.isLoading = false;
          this.globals.errorSweetAlert();
        });
        let postdata = { user_id: this.CookieService.get('user_token') };
        // let postdata = { user_id: this.globals.authData?.id };

        //when coming from browser back press i can get the updated data
        this.authService.getUserRoleList(postdata)
        .then((data) => { 
          this.router.navigate([state.url]);
          return false;
        },
        (error) => {
          this.globals.isLoading = false;
          this.globals.errorSweetAlert();
        });
      }
    } else {
      this.globals.IsLoggedIn = false;
      if (((state.url.split('/')[1]).split('?')[0]) == 'authentication' || state.url == '/admin/login' || state.url == '/page-not-found') {
        return true;
      } else {
        window.location.href = this.globals.amsnLoginUrl;
        return false;
      }
    }

  }

  setConfigData(route, state) {
    this.globals.IsLoggedIn = true;
    if (((state.url.split('/')[1]).split('?')[0]) == 'authentication' || state.url == '/admin/login') {
      this.router.navigate(['/landing-dashboard']);
      return false;
    } else if(this.globals.institute_details==null && (state.url.split('/')[1]).split('?')[0] != 'landing-dashboard') {
      this.router.navigate(['/landing-dashboard']);
      return false;
    } else {
      return true;
    }    
  }

}
